// Fonts
@import 'fonts';
@import url('https://fonts.googleapis.com/css?family=Nunito');

// Variables
@import 'variables';

@import 'general';

// Bootstrap
@import '~bootstrap/scss/bootstrap';
