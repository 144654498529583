@font-face {
    font-family: 'SPArte';
    src: url("../fonts/SPArte-Regular.ttf") format('truetype');
    font-weight: 400; /* Regular */
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'SPArte';
    src: url("../fonts/SPArte-Bold.ttf") format('truetype');
    font-weight: 700; /* Bold */
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'SPArte';
    src: url("../fonts/SPArte-SemiBold.ttf") format('truetype');
    font-weight: 600; /* Semibold */
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'SPArte';
    src: url("../fonts/SPArte-Light.ttf") format('truetype');
    font-weight: 300; /* Light */
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'SPArte';
    src: url("../fonts/SPArte-Medium.ttf") format('truetype');
    font-weight: 500; /* Medium */
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'KUniforma';
    src: url("../fonts/KUniforma-70Semibold.woff") format('truetype');
    font-weight: 600; /* Semibold */
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'KUniformaRitalic';
    src: url("../fonts/KUniforma-75SemiboldRitalic.woff") format('truetype');
    font-weight: 600; /* Semibold */
    font-style: italic;
    font-display: swap;
}
