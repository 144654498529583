main{
    overflow: hidden;
}

.header{
    width: 100%;
}

.header_inner{
    position: relative;
}

.header_container{
    display: flex;
    height: auto;
    min-height: 68px;
}

input{
    outline: none !important;
}

input:focus{
    outline: none !important;
    box-shadow: none !important;
}

input:-webkit-autofill,input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    transition: background-color 5000s ease-in-out 0s;
    background-color: #fff;
}

body{
    width: 100%;
    min-height: 100vh;
    font-family: 'SPArte' !important;
}

::-webkit-scrollbar {
    width: 8px;
    height: 100%;
}

::-webkit-scrollbar-track {
    background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 4px;
}

::-webkit-scrollbar-thumb:hover {
    background: #555;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none !important;
    margin: 0 !important;
}

input[type=radio]:checked{
    appearance: auto;
    accent-color: #000000 !important;
}

input[type=radio]:disabled{
    color: #C0C0C0 !important;
    border: 1px solid #C0C0C0;
}

input[type=radio]{
    appearance: none;
    width: 12px;
    height: 12px;
    border: 1px solid #000000;
    border-radius: 50%;
    background-color: transparent !important;
}

input[type=checkbox]:checked{
    background-color: #000000 !important;
}

p{
    font-family: var(--bs-body-font-family) !important;
}

.accordion{
    border: none !important;
    overflow-y: auto;
}

.accordion-button{
    background-color: white !important;
    color: black !important;
    border: none !important;
    font-size: 16px !important;
    font-weight: 500 !important;
    box-shadow: none !important;
}

.accordion-body{
    font-size: 16px;
    font-weight: 400;
    border: none !important;
}

.accordion-button::after{
    background-size: 1rem !important;
}

.accordion-button:focus{
    box-shadow: none !important;
    border: none !important;
}

.accordion-item {
    border: solid !important;
    border-width: 0.5px 0px 0px 0px !important;
    background-color: white !important;
    border-radius: 0% !important;
}

.table-responsive{
    background-color: #EDEDED !important;
}

.table, thead, tbody, tr, th, td{
    background-color: #EDEDED !important;
}

#divPrintMsg{
    background-color: #EDEDED !important;
}

.background-grey{
    background-color: #EDEDED !important;
}

.div-rounded{
    border-radius: 12px !important;
}

.btn{
    font-size: 16px !important;
    font-weight: 500 !important;
}

#modal-footer-center{
    justify-content: center !important;
}

.signIn-btn{
    min-width: 315px;
    max-width: 315px;
    height: 50px;
    font-size: 20px !important;
    font-weight: 500 !important;
}

.badge-pill{
    border-radius: 100% !important;
}

.bola{
    border-radius: 50%;
    border: 1.5px solid #000000;
    width: 5% !important;
    font-weight: 500;
}

.circle{
    border-radius: 50% !important;
    width: 5% !important;
    border: 2px solid;
    a{
        text-decoration: none;
        color: #000000;
        font-size: 20px;
    }
}

.round-div {
    width: 18px;
    height: 18px;
    border: 2px solid;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.plus-minus {
    font-size: 20px;
    display: flex;
    align-items: center;
    text-decoration: none;
}

.round-div-table {
    width: 14px;
    height: 14px;
    border: 1.5px solid;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.plus-minus-table {
    font-size: 16px;
    display: flex;
    align-items: center;
    //padding-bottom: 0.14rem;
    text-decoration: none;
}

.subtract{
    color: #000000;
}

.sum{
    color: #34A853;
}

.subtract{
    padding-bottom: 0.14rem;
}

#half-conditions{
    font-size: 12px;
    font-weight: 450;
}

.price-text{
    font-size: 16px;
    font-weight: 450;
}

.bola-vermelha{
    border-radius: 50%;
    border: 1px solid #ff0000;
    width: 5%;
}

.paymentTypeSelect{
    height: 68px !important;
}

#getBack{
    text-decoration: none !important;
    color: #000000 !important;
}

.alert{
    z-index: 1098 !important;
}

.modal{
    z-index: 1100 !important;
}

#modalLogin {
    z-index: 1200;
}

#modalSignUp, #msgErrorModal {
    z-index: 1600;
}

.link-black{
    color: #000000 !important;
}

.btn-cancelar{
    font-size: 12px;
    cursor: pointer;
}

.btn-cancelar:link{
    color: black;
}

.img-ticket-container{
    width: 345px;
    height: 194px;
}

.img-ticket-container, img{
    object-position: bottom;
}

.ticket-container{
    width: 345px;
}

.subtitle{
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
}

.hash-size{
    font-size: 12px;
    font-weight: 400;
}

.ticket-info-container{
    font-size: 12px;
}

#event-information{
    font-weight: 400;
    font-size: 16px;
}

.label-steps{
    font-weight: 500;
    font-size: 20px;
}

.title-login{
    font-size: 30px !important;
    font-weight: 500 !important;
    line-height: 33px !important;
    font-style: normal;
    letter-spacing: -0.9px;
}

.btn-close{
    opacity: unset !important;
}

.button{
    font-weight: 500;
    font-size: 16px;
}

.fs-16{
    font-size: 16px;
}

.section-cookies{
    position: fixed;
    bottom: 0;
    left: 0;
    background: black;
    color: white;
    width: 100%;
    z-index: 1040 !important;
}

.active{
    display: block;
}

.hide{
    display: none;
}

.cookies{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 10px;
}

.cookies-text{
    font-weight: normal;
}

.cookies-text_title{
    font-size: 14px;
    margin-bottom: 0;
    justify-content: center;
}

.cookies-text_link{
    color: inherit;
    text-decoration: underline;
}
.cookies-content{
    margin: 15px 0;
    color: white;
    transition: all 0.6s;
}

.bottom-cookies{
    width: 315px;
    height: 40px;
    border-radius:100px;
    background-color: white;
    border: none;
    color: black;
}

.bottom-cookies:hover{
    background-color: #d9d9d9;
    color: #ffffff;
}

.fs-medium{
    font-weight: 500;
}

.modal-title{
    font-weight: 500;
    font-size: 24px;
}

.type-name{
    font-weight: 500;
    font-size: 20px;
}

.subtotal-content{
    font-size: 16px;
    font-weight: 500;
}

.send-to{
    font-size: 14px;
    font-weight: 400;
}

.name-send{
    font-size: 16px;
    font-weight: 500;
}

.payment-steps-title{
    font-size: 16px;
    font-weight: 500;
}

#label-cupon{
    font-size: 12px;
    font-weight: 400;
}

small{
    font-size: 12px;
    font-weight: 400;
}

.text-body-modal{
    font-size: 16px;
    font-weight: 400;
}

.time-pix{
    font-size: 16px;
    font-weight: 500;
}

#chavePix{
    font-size: 14px;
    font-weight: 400;
}

#instructions{
    p{
        font-size: 14px;
        font-weight: 400;
    }
}

.success-title{
    font-size: 24px;
    font-weight: 500;
    line-height: 1.2;
}

.order-title{
    font-size: 16px;
    font-weight: 500;
}

.cancel-btn{
    font-size: 12px;
    font-weight: 450;
}

.ticket-info-title{
    font-size: 12px;
    font-weight: 500;
    line-height: 1;
}

.ticket-info{
    font-size: 12px;
    font-weight: 400;
}

.error-message{
    font-size: 14px;
    font-weight: 400;
}

.fixed-whatsapp{
    position: fixed;
    right: 0;
    bottom: 150px;
    z-index: 1096;
}

.bottom-footer{
    width: 345px !important;
    height: 50px !important;
}

.button{
    background-color: #000000;
    color: #ffffff;
    border-radius:100px;
    border: none;
}

img.icon{
    display: inline-block;
    vertical-align: middle;
    border-radius: 10px;
    width: 15px;
    height: 15px;
}

.btn-dark{
    background-color: #000000 !important;
}

.cart-badge{
    font-size: 9px !important;
}

.gray-icon{
    fill: #000000;
}

.disabled .gray-icon{
    fill: #C0C0C0;
}

.disabled{
    color: #C0C0C0 !important;
    pointer-events: none !important;
}

.progress{
    height: 5px !important;
    background-color: #FCC1C1 !important;
}

.progress-bar{
    width: 100%;
}

.my-orders{
    font-size: 24px;
    font-weight: 500;
}

.qtdType{
    background-color: transparent;
}

.background-image{
    color: #ffffff;
    background-size: cover;
    background-attachment: fixed;
    background-position: center;
    height: 100vh;
}

.date-container{
    --bs-gutter-x:1.3rem;
    --bs-gutter-y:0;
    width: 100%;
    max-width: 1440px;
    padding-right: calc(var(--bs-gutter-x) * 0.5);
    padding-left: calc(var(--bs-gutter-x) * 0.5);
    margin-right: auto;
    margin-left: auto;
    min-height: calc(100vh - 98px);
}

.btn-home-container{
    margin-top: 10vh !important;
    margin-bottom: 1rem;
    justify-content: center;
}

.time-local{
    font-size: 16px;
    font-weight: 400;
    padding-right: 0 !important;
    padding-left: 0 !important;
    flex: 0 0 auto;
    width: auto !important;
}

.footer-container{
    display: flex !important;
}

#header-infos{
    color: #000000;
    text-decoration: none !important;
}

@media (min-width: 768px){
    .footer-container{
        justify-content: space-between;
    }
    .background-image{
        background-image: linear-gradient(rgba(0,0,0,0.5), rgba(0,0,0,0.5)), url('../../public/images/home/desktop/bilheteria-banner-home-desktop-bienal25.webp');
    }

    .container-fluid{
        max-width: 1440px;
    }

    .btn-home-container{
        padding-left: 2vh;
    }

    .event-days-container{
        padding-left: 2vh;
    }

    .desktop-only{
        display: flex;
    }

    .desktop-only-block{
        display: block;
    }

    .mobile-only{
        display: none;
    }

    .accordion-button{
        padding-left: 0 !important;
    }

    .accordion-body{
        padding: 0 35px 0 0 !important;
    }

    .accordion-div{
        max-height: 205px;
        overflow-y: auto;
    }

    .title-steps{
        font-size: 24px;
        font-weight: 500;
        line-height: 25px;
        letter-spacing: -0.72px;
    }

    .footer-btn-container{
        flex: 0 0 auto ;
        width: 53.33% !important;
    }

    .title-event{
        font-size: 24px;
        font-weight: 500;
        line-height: 25px;
        letter-spacing: -0.72px;
    }

    #div-image{
        height: 385px;
    }

    #logo-header{
        max-width: 85px;
    }

    .subtotal{
        color: white;
    }

    .footer{
        height: 98px;
        background-color: black;
        border-top-right-radius: 30px;
        justify-content: space-between !important;
    }

    .footer-subtotal{
        justify-content: space-between;
    }

    .button:disabled{
        background-color: #d9d9d9;
        color: #ffffff;
    }

    .button:hover{
        background-color: #d9d9d9;
        color: #ffffff;
    }

    .space-bottom{
        padding-bottom: 10vh;
    }

    #signinButton{
        width: 343px;
        height: 50px;
        font-size: 16px;
        border: thin solid #888;
        white-space: nowrap;
    }

    .btnGoogle{
        background: white;
        color: #444;
    }

    .btnApple{
        background: white;
        color: #444;
    }

    .btnWithNumber{
        background: #000000;
        color: #ffffff;
    }

    #signinButton:hover{
        cursor: pointer;
    }

    span.label{
        font-family: 'SPArte';
        font-weight: normal;
    }

    span.buttonText{
        display: inline-block;
        vertical-align: middle;
        font-size: 20px;
        font-weight: 500;
        font-family: 'SPArte';
    }

    .modal-small{
        max-width: 388px !important;
    }

    #qtdSelected{
        display: none;
        background-color: black;
        color: #ffffff;
    }

    .headerStep{
        border: none;
    }

    .section-cookies{
        padding: 15px 0;
        border-top-right-radius: 30px;
    }

    .cookies{
        flex-direction: row;
    }

    .cookies-content{
        margin-left: 40px;
        margin-top: 0;
        margin-bottom: 0;
    }

    .available-app{
        font-size: 14px;
        font-weight: 500;
    }

    .available-text{
        font-size: 14px;
        font-weight: 400;
    }

    .event-description{
        height: max-content;
        padding-bottom: 10px;
        box-shadow: 0px -20px 20px 0px white inset;
        opacity: 0.8;
        --bg-opacity: 0.8;
    }

    .modal-footer{
        border: none !important;
    }

    .checkout-steps{
        flex: 0 0 auto;
        width: 83.33333333% !important;
    }

    .btn-container{
        width: 100%;
        margin-bottom: 1rem !important;
        margin-right: 0.5rem !important;
        margin-left: 0.5rem !important;
        align-items: center !important;
        justify-content: space-between !important;

        button{
            width: 45%;
            margin-bottom: 1rem !important;
        }
    }
}

@media (max-width: 767px){
    .footer-container{
        justify-content: center;
    }

    .background-image{
        background-image: linear-gradient(rgba(0,0,0,0.5), rgba(0,0,0,0.5)), url('../../public/images/home/mobile/bilheteria-banner-home-mobile-bienal25.webp');
    }

    .container-fluid{
        padding-left: 1.3rem;
        padding-right: 1.3rem;
        max-width: 1440px;
    }

    .desktop-only, .desktop-only-block{
        display: none;
    }

    .mobile-only{
        display: flex;
    }

    .accordion-div{
        max-height: 300px;
        overflow-y: auto;
    }

    .scrollable-content{
        height: 760px;
        overflow-y: hidden;
        overflow-x: hidden;
    }

    .title-steps{
        font-size: 16px;
        font-weight: 500;
        line-height: 25px;
        letter-spacing: -0.72px;
    }

    .title-event{
        font-size: 20px;
        font-weight: 500;
        letter-spacing: -0.72px;
    }

    #myProfile{
        font-size: 24px;
        font-weight: 500;
    }

    .logo-header-white{
        max-height: 20px;
    }

    .logo-header-black{
        max-height: 14px;
    }

    #div-image{
        height: 194px;
    }

    .footer{
        height: 98px;
        background-color: white;
        border-top: 1px solid #d9d9d9;
        justify-content: center !important;
    }

    .button{
        background-color: black;
        color: white;
    }

    .button:disabled{
        background-color: #d9d9d9;
        color: #ffffff;
    }

    .btn-home-bottom-mobile{
        position: relative;
        top: 41vh;
    }

    .fixed-bottom-mobile{
        position: fixed;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 1096;
    }

    .fixed-top-mobile{
        height: 46px;
        right: 0;
        left: 0;
        z-index: 1096;
    }

    #qtdSelected{
        display: none;
        background-color: white;
        color: #000000;
    }

    .headerStep{
        border-bottom: 1px solid #000000 ;
        font-size: 16px;
        font-weight: 500;
    }

    .section-cookies{
        position: fixed;
        bottom: 0;
        left: 0;
        background: black;
        color: white;
        width: 100%;
        z-index: 1040 !important;
    }

    .bottom-cookies{
        width: 315px;
        height: 40px;
        border-radius:100px;
        margin-top: 20px;
        background-color: white;
        border: none;
        color: black;
    }

    #totalMobile{
        font-size: 20px;
        font-weight: 500;
    }

    .subtotal-content{
        font-size: 18px;
        font-weight: 500;
    }

    .available-app{
        font-size: 12px;
        font-weight: 500;
    }

    .available-text{
        font-size: 12px;
        font-weight: 400;
    }

    .modal-footer{
        border-top: 1px solid !important;
        border-color: #C0C0C0 !important;
    }

    .checkout-steps{
        flex: 0 0 auto;
        width: 100%;
        padding-left: 0 !important;
        padding-right: 0 !important;
    }

    .btn-container{
        margin-bottom: 1rem !important;
        margin-right: 1rem !important;
        margin-left: 1rem !important;
        align-items: center !important;
        justify-content: center !important;

        button{
            width: 100%;
        }
    }
}